import React from "react"
import BoxBase from "./boxes/base"
import AniCard01 from "./boxes/ani_card_01"

const Boxes = props => {
  const items = props.children
  let objReturn = []
  let iCount = 0 

  Object.keys(items).forEach(item => {
    
    const path = "/" + items[item].parent.relativeDirectory
    const box = items[item].frontmatter
    const key = "box_" + iCount.toString()
    const summary = truncate(items[item].html, 21) + "..."

   if (path.includes("/team/") || path.includes("/services/")) {

      objReturn.push( <BoxBase key={key} box={box} path={path} summary={summary} /> )

    } else {

      objReturn.push( <AniCard01 key={"ani_" + key} box={box} path={path} summary={summary} counter={iCount} /> )

    }

    iCount ++

  })
  
  return objReturn

}

function truncate(str, no_words) {
    let strippedString = str.replace(/(<([^>]+)>)/gi, "")
    return strippedString.split(" ").splice(0,no_words).join(" ");
}

export default Boxes




  /*
  if ('socials' in box && (box.socials !== null)) {
    let objReturn = []
    let iCount = 0
    let sKey = ""

    box.socials.forEach(item => {

      sKey = box.title
      while (sKey.includes(" ")) { sKey = sKey.replace(" ", "") }
      sKey += "_" + item.social + "_"
      sKey = sKey.toLowerCase()
      sKey += iCount.toString()

      objReturn.push(
        <NavLink key={sKey} name={item.social + " " + box.title} path={item.url} css={"mr-1 text-" + item.color} >
          <SocialIcon social={item.social} css="text-2xl" />
        </NavLink>
      )
      iCount ++

    })

    return (
      <div className="flex flex-wrap overflow-hidden xl:-mx-1 pl-1">
  
        <div className="w-full overflow-hidden xl:my-1 xl:px-1 xl:w-9/12">
          <NavLink name={box.title} path={path} css="" >
            <span className={css}>{box.title}</span>
          </NavLink>
        </div>
        
        <div className="w-full overflow-hidden xl:my-1 xl:px-1 xl:w-3/12 text-right">
          {objReturn}
        </div>
  
      </div>
    )
  } else {

    return (
      <div className="flex flex-wrap overflow-hidden xl:-mx-1 pl-1">
        <div className="w-full overflow-hidden xl:my-1 xl:px-1 h-36">
          <NavLink name={box.title} path={path} css={css} >
            <span className={css}>{box.title}</span>
          </NavLink>
        </div>
      </div>
    )

  }

  */