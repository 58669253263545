import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import NavLink from "../navlink"
import SocialIcon from "../socialicon"

const BoxBase = ({box, path, summary}) => {

    const css = {
      box: "my-2 px-2 w-full overflow-hidden md:w-1/2 lg:w-1/3 xl:w-1/4",
      title: "font-bold text-xl h-14 mb-2",
      text: "text-gray-700 text-base bg-gray-50 p-2",
      image: "rounded-xl",
      imageStyle: { width: "100%", height: "0", paddingBottom: "55%" },
    }

    return (
        <div className={css.box}>

        <NavLink name={box.title} path={path}>
          <GatsbyImage
            className={css.image}
            image={box.images[0].image.childImageSharp.gatsbyImageData}
            alt={box.images[0].dida || "Image"}
            style={css.imageStyle}
          />
        </NavLink>

          <div className="py-4">
            {boxTitle(box, css.title, path)}

            <NavLink name={box.title} path={path}>
              <p className={css.text}>
                {summary}
              </p>
            </NavLink>

          </div>

      </div>
    )
}

function boxTitle(box, css, path) {

    let span_LEFT = ""
    let title_LEFT = null
    let title_RIGHT = null
  
    if ('socials' in box && (box.socials !== null)) {
      let objReturn = []
      let iCount = 0
      let sKey = ""
  
      box.socials.forEach(item => {
  
        sKey = box.title
        while (sKey.includes(" ")) { sKey = sKey.replace(" ", "") }
        sKey += "_" + item.social + "_"
        sKey = sKey.toLowerCase()
        sKey += iCount.toString()
  
        objReturn.push(
          <NavLink key={sKey} name={item.social + " " + box.title} path={item.url} css={"mr-1 text-" + item.color} >
            <SocialIcon social={item.social} css="text-2xl" />
          </NavLink>
        )
        iCount ++
  
      })
  
      span_LEFT = "xl:w-9/12"
      title_RIGHT = (
        <div className="w-full overflow-hidden xl:my-1 xl:px-1 xl:w-3/12 text-right">
          {objReturn}
        </div>
      )
  
    }
  
    title_LEFT = (
      <div className={"w-full overflow-hidden xl:my-1 xl:px-1 md:h-20 lg:h-24 xl:h-28 2xl:h-14 " + span_LEFT}>
        <NavLink name={box.title} path={path} css={css} >
          <span className={css}>{box.title}</span>
        </NavLink>
      </div>
    )
  
  
    return (
      <div className="flex flex-wrap overflow-hidden xl:-mx-1 pl-1">
        {title_LEFT}
        {title_RIGHT}
      </div>
    )
  
  }

export default BoxBase
