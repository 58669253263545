import React from "react"
import { Link } from "gatsby"

const LinkButtons = props => {
  return ( get_LinkButtons(props) )
}

/*

    <div className={props.mainCss}>
      {get_LinkButtons(props)}
    </div>
*/

// false, iTotImages, head.children, page.siblings, page.parents, path nm-flat-red-500
// JSON.stringify(props)  {JSON.stringify(props.parents)}


function get_Link(item, selectedPath, key, css, sPath="") {

  let selected = ""
  if (selectedPath.includes(item)) { selected = "bg-gray-400 font-bold text-white " } else { selected = "bg-gray-100 font-semibold text-gray-700 " }

  let linkName = item
  while(linkName.includes("-")) {
    linkName = linkName.replace("-", " ")
  }
  
  return (
    <Link
      key={key}
      id={key}
      className={selected + css}
      to={sPath + item}
    >{linkName}</Link>
  )
}


function get_Links(items, prefix, selectedPath, selectedParent=null, keys=null) {

  const sPath = Boolean(selectedParent) ? "../" : "/"

  let objReturn = []
  let iCount = 0
  let divCss = "py-2"
  let itemCss = "inline-block rounded-full px-3 py-1 text-sm text-center capitalize m-1"

  // Se presenti, questo rimuove team e about dal menu buttons
  try {
    items.forEach(element => {
      if (element === "about" || element === "team") {
        items.splice(iCount, 1);
      }
      iCount ++
    });
  } catch {
    iCount = 0
  }

  let aTest = selectedPath.split("/")
  if ( prefix === "parent" || aTest[2] === "" ) {
    divCss += " flex justify-between"
    itemCss += " m-0"
  } else {
    divCss += " flex flex-wrap"
    itemCss += " "
  }

  iCount = 0
  if (keys) {

    // get_Link(prefix + "_" + iCount.toString(), sSlash + items[item].parent.relativeDirectory, item, selectedPath)
    keys.forEach(item => {
      objReturn.push(
        get_Link(item, selectedPath, prefix + "_" + iCount.toString(), itemCss)
      )
      iCount ++
    })

  } else {

    items.forEach(item => {
      objReturn.push(
        get_Link(item, selectedPath, prefix + "_" + iCount.toString(), itemCss, sPath)
      )
      iCount ++
    })

  }

  return (<div key={prefix} className={divCss}>{objReturn}</div>)
}

function get_LinkButtons(props) {
  let objReturn = []
  const selectedParent = "/" + props.selectedPath.split("/")[1] + "/"

  if (props.parents) { 
    // objReturn.push(get_Items(props.parents, "parent", selectedParent)) 
    objReturn.push(get_Links(props.parents, "parent", selectedParent)) 
  }

  if (props.siblings) { 
    // objReturn.push(get_Items(props.siblings, "sibling", props.selectedPath))
    objReturn.push(get_Links(props.siblings, "siblings", props.selectedPath, selectedParent)) 
  }

  if (props.children) { 
    // objReturn.push(get_Items(props.children, "children", props.selectedPath, Object.keys(props.children)))
    objReturn.push(get_Links(props.children, "children", props.selectedPath, selectedParent, Object.keys(props.children))) 
  }

  return objReturn
}






export default LinkButtons