import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const ZoomGallery = props => {
  const wrapKey = "wKey_"
  const imgKey = "iKey_"
  const btnKey = "bKey_" // questi sono i prefissi per i wrapper le immagini ed i pulsanti del box immagini

  // my-1 w-full overflow-hidden
  // flex flex-wrap -mx-px overflow-hidden
  return (
    <>
      <div className="my-1 px-1 w-full overflow-hidden">
        {getBoxImages(props.images, wrapKey, imgKey)}
      </div>

      <div className="flex flex-wrap -mx-px overflow-hidden">
        {getBtnImages(props.images, wrapKey, btnKey)}
      </div>
    </>
  )
}

function getBoxImages(immagini, wrapKey, imgKey) {
  let objReturn = []

  let iCount = 0
  let sCount = ""
  let sHidden = ""
  let img = ""

  immagini.forEach(immagine => {
    sCount = iCount.toString()
    const wKey = wrapKey + sCount
    const iKey = imgKey + sCount

    if (iCount > 0) {
      sHidden = "hidden"
    }

    img = immagine.image.childImageSharp.gatsbyImageData
    objReturn.push(
      <div key={wKey} id={wKey} className={sHidden}>
        <GatsbyImage
          key={iKey}
          loading="eager"
          image={img}
          alt={immagine.dida || "missing alt image"}
          imgClassName="rounded-t-xl md:object-cover w-full"
          className="rounded-t-xl md:object-cover w-full"
        />

        { getDida(immagine.dida || "Didascalia mancante", iKey) }
      </div>
    )

    iCount++
  })

  return objReturn
}

function getDida(dida, imgKey) {
  let title = ""
  let text = ""
  const separator = ":"

  if (dida.includes(":")) {
    let aDida = dida.split(separator)
    title = aDida[0].trim() + separator // qui ho inserito i 2punti, poiché in assenza di titolo devono sparire
    aDida.shift() // rimuove il primo elemento, mentre per l'ultimo è aDida.pop()
    text = aDida.join(separator).trim()
  } else {
    text = dida.trim()
  }

  return (
    <div key={"dida_" + imgKey} className="px-2 font-roboto capitalize h-18">
      <span className="font-bold">{title}</span>{" "}
      <span className="italic">{text}</span>
    </div>
  )
}

function getBtnImages(immagini, wrapKey, btnKey) {
  let objReturn = []
  let iCount = 0
  let sCount = ""
  let iTotale = immagini.length

  immagini.forEach(item => {
    sCount = iCount.toString()

    let sCss = " "
    if (iCount === 0) {
      sCss += "rounded-bl-xl"
    } else if (iCount === iTotale - 1) {
      sCss += "rounded-br-xl"
    }

    objReturn.push(getBtnImage(
      btnKey + sCount,
      wrapKey,
      btnKey,
      iCount,
      iTotale,
      item.image,
      sCss
    ))

    iCount++
  })

  return objReturn
}

function getBtnImage(btnKey, wrapKey, btnPrefix, iCount, iTotale, img, sCss) {
  // "my-1 px-1 w-1/5 overflow-hidden outline-false opacity-50 focus:outline-none hover:opacity-100"
  // my-px px-px w-full overflow-hidden md:w-1/5
  const sWidth = "w-1/" + iTotale.toString()
  const cssWidth = "sm:" + sWidth + " md:" + sWidth + " lg:" + sWidth + " xl:" + sWidth + " 2xl:" + sWidth
  const sClass = "my-px px-px w-full overflow-hidden " + cssWidth + " outline-false opacity-50 focus:outline-none hover:opacity-100"

  let click = () => {
    let element = null
    let btnImage = null

    for (let i = 0; i < iTotale; i++) {
      let sCount = i.toString()
      element = document.getElementById(wrapKey + sCount)
      btnImage = document.getElementById(btnPrefix + sCount)

      if (iCount === i) {
        element.classList.remove("hidden")
        btnImage.classList.remove("opacity-50")

      } else {
        element.classList.add("hidden")
        btnImage.classList.add("opacity-50")
      }
    }
  }

  return (
    <button key={btnKey} id={btnKey} className={sClass} onClick={click}>
      <GatsbyImage
        loading="eager"
        image={img.childImageSharp.gatsbyImageData}
        alt="#"
        imgClassName={sCss}
        className={sCss}
      />
    </button>
  )
}

export default ZoomGallery