import React from 'react'
import NavLink from "../navlink"
import { BackgroundImg } from "../functions/background_img"
import "./ani_card_01.css"

const AniCard01 = ({box, path, summary, counter}) => {
    const bgImg = BackgroundImg(box.images[0].image.childImageSharp.gatsbyImageData.images.fallback.srcSet, "mid")
    const bgStyle = {
        backgroundImage: 'url("' + bgImg + '")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
    }

    // box: w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-1
    //card: h-full p-6 rounded-2xl bg-gradient-to-b from-transparent to-black
    //card-content: pt-64 text-white font-sans

    const title_MultiLine = text_Multiline(box.title, counter)

    let y_Padding = 0
    switch (counter) {
        case 0:
        case 1:
        case 3:
        case 13:
        case 14:
            y_Padding = 44
            break;

        case 5:
            y_Padding = 28
            break;

        case 12:
            y_Padding = 52
            break;
    
        default:
            y_Padding = 36
    }
    
    // `card-content h-full transform translate-y-${y_Padding}`
    
    return (
        <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-1">
            <NavLink name={box.title} path={path}>
                <div className="card h-full" style={bgStyle}>
                    <div className={`card-content h-full transform translate-y-${y_Padding}`}>
                        <h2 className="card-title">{ title_MultiLine }</h2>
                        <p className="card-body">
                            {summary}
                        </p>
                    </div>
                </div>
            </NavLink>
        </div>
    )
}

function text_Multiline(text, counter, iLimit=37) {
    let objReturn = []
    let iCount = 0
    let sString = ""
    let sAppend = ""

    text.split(" ").forEach(item => {

        sAppend = item + " "
        if ((sString + sAppend).length <= iLimit) {
            sString += sAppend
            
        } else {
            objReturn.push( <span key={`textbox_${counter}_${iCount}`}>{sString.trim()}<br /></span> )
            sString = sAppend
            iCount ++
        }
        
    })
    objReturn.push( <span key={`textbox_${counter}_${iCount}`}>{sString.trim()}<br /></span> )

    return objReturn
}

export default AniCard01

/*



        <div className="card w-full md:w-1/2 lg:w-1/3 xl:w-1/4" style={bgStyle}>
            <div className="card-content">
                <h2 className="card-title">{ text_Multiline(box.title, counter) }</h2>
                <p className="card-body">
                    {summary}
                    <NavLink name="Read More" path={path} css="button" />
                </p>
            </div>
        </div>






    const bgImg = BackgroundImg(box.images[0].image.childImageSharp.gatsbyImageData.images.fallback.srcSet, "mid")
    const bgStyle = {
        backgroundImage: 'url("' + bgImg + '")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
    }

    const textColor = "text-white"
    const boxBorder = "border border-gray-400"
    const boxRounded = "xl"
    const boxSize = "w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
    const boxMix = `${textColor} font-sans pt-64 max-w-sm overflow-hidden` // grazie a overflow-hidden non mi preoccupo degli angoli scuri creati a riga 25
    const boxAnimate = `transform hover:scale-95 transition duration-500 ease-in-out`
    const boxCss = `${boxBorder} rounded-${boxRounded} ${boxSize} ${boxMix} ${boxAnimate}`
    const bgButton = "bg-blue-600"

    return (
        <div className={boxCss} style={bgStyle}>
            <div className={`p-6 bg-gradient-to-b from-transparent to-black`}>
                <h2 className={cssStyles.cardTitle}>{box.title}</h2>
                <p>
                    <span className="opacity-90">{summary}</span>
                    <NavLink name="Read More" path={path} css={`${bgButton} ${textColor} ml-4 hover:text-black hover:bg-gray-400 text-sm border rounded-${boxRounded} py-1 px-2`} />
                </p>
            </div>
        </div>
    )






*/

// <div>{JSON.stringify( value )}</div>



// /static/0705aa45b23535bd2aa1dee490353547/14fdd/duomo.jpg 188w, 
// /static/0705aa45b23535bd2aa1dee490353547/ba673/duomo.jpg 375w, 
// /static/0705aa45b23535bd2aa1dee490353547/ba673/duomo.jpg

// /static/0705aa45b23535bd2aa1dee490353547/12e5e/duomo.jpg 750w, 
// /static/0705aa45b23535bd2aa1dee490353547/12831/duomo.jpg 1500w