import React from "react"

const Dida = props => {
  return (
    <div className={props.mainCss}>
        {getDida(props.dida, props.imgKey)}
    </div>
  )
}

function getDida(dida, imgKey) {
    let title = ""
    let text = ""
    const separator = ":"
  
    if (dida.includes(":")) {
      let aDida = dida.split(separator)
      title = aDida[0].trim() + separator // qui ho inserito i 2punti, poiché in assenza di titolo devono sparire
      aDida.shift() // rimuove il primo elemento, mentre per l'ultimo è aDida.pop()
      text = aDida.join(separator).trim()
    } else {
      text = dida.trim()
    }
  
    return (
      <div key={"dida_" + imgKey} className="px-2 font-roboto capitalize h-12">
        <span className="font-bold">{title}</span>{" "}
        <span className="italic">{text}</span>
      </div>
    )
  }

export default Dida
