import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ZoomGallery from "../components/zoomgallery"
import HashTags from "../components/hashtags"
import LinkButtons from "../components/linkbuttons"
import Dida from "../components/dida"
import Boxes from "../components/boxes"
import { BackgroundImg } from "../components/functions/background_img"


/**
 ** ATTENZIONE 
 * SE VUOI AGGIUNGERE ALTRI PARAMETRI AL FRONTMATTER (COME HAI FATTO PER I SOCIAL), RICORDA CHE DEVI ANCHE MODIFICARE LA QUERY IN GATSBY NODE
 */


const Intro = props => {
  const path = props.path
  const page = props.pageContext
  const head = page.frontmatter
  const containerCss = {
    left: "lg:w-6/12 xl:w-5/12",
    right: "lg:w-6/12 xl:w-7/12",
  }


  let ogImg = ""
  let ogDesc = ""
  try {
    ogImg = BackgroundImg(head.images[0].image.childImageSharp.gatsbyImageData.images.fallback.srcSet, "small")
    ogDesc = head.images[0].dida
  } catch {
    ogDesc = head.description
  }
  

  // location, title, pageKeywords, description, isIndex=false, children     {JSON.stringify(props)}
  // Seo({ description, lang, meta, title, kwords })
  return (
    <Layout location={path} title={head.title} kwords={head.keywords} description={head.description}>
      <Seo title={head.title} kwords={head.keywords} image={ogImg} description={ogDesc} path={path} />
      <div className="flex flex-wrap -mx-1 px-2 overflow-hidden">
        {getPage(path, page, head, containerCss)}
      </div>
    </Layout>
  )
}

function get_ImageZone(iTotImages, path, page, head, containerCss) {
  let boxImage = null

  if (iTotImages === 1) {
    boxImage = getBoxImage(head.images[0])
  } else if (iTotImages > 1) {
    boxImage = <ZoomGallery images={head.images} />
  }

  return (
    <div key="boxImage" className={"my-2 px-2 w-full overflow-hidden " + containerCss.left} >
      {boxImage}
      {get_LinkButtons(true, iTotImages, head.children, page.siblings, page.parents, path)}
    </div>
  )
}


function getPage(path, page, head, containerCss) {
  let objReturn = []
  let iTotImages = 0
  try { iTotImages = head.images.length } catch { iTotImages = 0 }

  objReturn.push( get_ImageZone(iTotImages, path, page, head, containerCss) )

  if (iTotImages < 1) { containerCss.right = "" }

  objReturn.push(
    <div key="boxText" className={"my-2 px-2 w-full overflow-hidden " + containerCss.right} >
      <div className="text-gray-700" dangerouslySetInnerHTML={{ __html: page.html }} />
    </div>
  )

  objReturn.push(
    <HashTags
      key="hashtags"
      list={head.keywords}
      mainCss="my-1 py-6 px-1 w-full overflow-hidden text-right"
      itemCss="inline-block px-1 text-xs font-normal text-gray-400 mr-2 mb-2"
    />
  )

  objReturn.push(
    <div key="boxItems" className=" flex flex-wrap -mx-1 px-2 overflow-hidden">
      {get_Boxes(head.children)}
    </div>
  )

  return objReturn
}

function get_Boxes(children, path) {
  if (children) { return <Boxes children={children} path={path} /> }
  else { return "" }
}

function getBoxImage(objItem) {
  // Questo interviene nel caso di una singola immagine
  let objReturn = []
  
  objReturn.push(
    <div key="Single_Img">
      <GatsbyImage
        loading="eager"
        image={objItem.image.childImageSharp.gatsbyImageData}
        alt={objItem.dida}
        width={100}
        className="my-1 px-1 w-full overflow-hidden rounded-xl"
      />
      <Dida
        dida={objItem.dida}
        imgKey="dida"
      />
    </div>
  )

  return objReturn
}

function get_LinkButtons(isImgZone, iTotImages, children, siblings, parents, path) {

  // Questo impedisce la creazione del menu indesiderato in team ed about
  if (path.includes("/about")) {
    siblings = null
  } else if ( path !== "/team" && path.includes("/team")) {
      parents = null
  }
  

  if ( (!isImgZone && iTotImages === 0) || (isImgZone && iTotImages > 0) ) {
    return ( <LinkButtons children={children} siblings={siblings} parents={parents} selectedPath={path} /> )
  } else {
    return ("")
  }
}

export default Intro